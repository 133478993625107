import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "eteration-ui-components"
    }}>{`Eteration UI Components`}</h1>
    <p>{`What's inside?`}</p>
    <ul>
      <li parentName="ul">{`Bootstrap 4.6`}</li>
      <li parentName="ul">{`date-fns`}</li>
    </ul>
    <h3 {...{
      "id": "in-your-projects"
    }}>{`In your projects`}</h3>
    <ul>
      <li parentName="ul">{`create or edit `}<strong parentName="li">{`.npmrc`}</strong>{` file`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`registry=https://nexus.eteration.com/repository/npm-all/
`}</code></pre>
    <ul>
      <li parentName="ul">{`install with npm or yarn command`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`yarn add @eteration/ui-components

# or

npm install --save @eteration/ui-components
`}</code></pre>
    <ul>
      <li parentName="ul">{`Add the css file to your project.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import '@eteration/ui-components/dist/ui-components.css';
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      